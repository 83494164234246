<template>
	<v-list-group v-if="section" :value="expanded" class="response-nav-group">
		<template v-slot:activator>
			<response-nav-item
				:section="id"
				:buttons="false"
				:text="section.name"
				:completion="averageCompletion"
				:theme="true"
			>
			</response-nav-item>
		</template>
		<response-nav-item
			class="indent-1"
			v-for="single in singles"
			:key="`${single.response}-${single.rule}`"
			:status="true"
			:section="id"
			:response="single.response"
			:text="single.name"
			:completion="single.completion_rate"
			@click="goTo(single.response)"
		>
		</response-nav-item>
	</v-list-group>
</template>
<style lang="less">

.response-nav-group {
	.v-list-group__header {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.v-list-item__icon.v-list-group__header__append-icon {
		justify-content: end !important;
	}	
}
</style>
<script>
import ResponseNavItem from "@c/navigation/assessment/sections/ResponseNavItem";

export default {
	name: "ResponseNavGroup",
	props: {
		id: { type: String }, // the ID of the section,
	},
	components: {
		ResponseNavItem,
	},
	data: () => {
		return {
			expanded: false,
		};
	},
	computed: {
		active() {
			return this.current.section == this.id;
		},
		section() {
			return this.$store.state.sections.data[this.id];
		},
		current() {
			return this.$store.state.current;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		responses() {
			return this.$store.state.assessment.data.responses[this.id];
		},
		sectionResponses() {
			return this.$store.state.sectionResponses.data;
		},
		order() {
			return this.$store.getters.order;
		},
		singles() {
			const self = this;

			let order = self.order.filter((r) => r.section == self.id);

			let rules = Object.fromEntries(
				order.map((item) => {
					return [
						item.response,
						this.$store.getters.getRuleForResponse(item.response),
					];
				})
			);
			return order.map((item) => {
				let rule = rules[item.response];
				return {
					rule: rule.value,
					name: rule.name || self.categoryOptions[rule.value].name,
					response: item.response,
					completion_rate:
						self.sectionResponses[item.response].completion_rate,
				};
			});
		},
		averageCompletion() {
			var averages = this.singles.map((a) => a.completion_rate);
			return this.mwutils.average(averages);
		},
	},
	watch: {
		current: {
			deep: true,
			handler(value) {
				if (value.section == this.id) {
					this.expanded = true;
				}
			},
		},
	},
	methods: {
		goTo(response) {
			this.$store.commit("GO_TO", { section: this.id, response });
		},
	},
};
</script>
