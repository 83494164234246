<template>
	<small class="mr-2">{{ statusText }}</small>
</template>

<script>
export default {
	name: "StatusChip",
	props: {
		id: { type: String }, // the id of the response,
	},
	computed: {
		isDelegate() {
			return this.$store.getters.isDelegate;
		},
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
		statusText() {
			if (!this.response) {
				return;
			}
			if (this.response.status == "delegated" && this.isDelegate) {
				return "In Progress";
			}
			return this.mwutils.prettyPrint(this.response.status, "TITLE");
		},
	},
};
</script>
